function pad(val, len) {
    val = String(val);
    len = len || 2;
    while (val.length < len) {
        val = '0' + val;
    }
    return val;
}
export default {
    token: /d{1,4}|M{1,4}|yy(?:yy)?|S{1,3}|Do|ZZ|([HhMsDm])\1?|[aA]|"[^"]*"|'[^']*'/g,
    literal: /\[([^]*?)\]/gm,
    formatFlags: {
        D: function(dateObj) {
            return dateObj.getDay();
        },
        DD: function(dateObj) {
            return pad(dateObj.getDay());
        },
        d: function(dateObj) {
            return dateObj.getDate();
        },
        dd: function(dateObj) {
            return pad(dateObj.getDate());
        },
        M: function(dateObj) {
            return dateObj.getMonth() + 1;
        },
        MM: function(dateObj) {
            return pad(dateObj.getMonth() + 1);
        },
        yy: function(dateObj) {
            return pad(String(dateObj.getFullYear()), 4).substr(2);
        },
        yyyy: function(dateObj) {
            return pad(dateObj.getFullYear(), 4);
        },
        H: function(dateObj) {
            return dateObj.getHours();
        },
        HH: function(dateObj) {
            return pad(dateObj.getHours());
        },
        m: function(dateObj) {
            return dateObj.getMinutes();
        },
        mm: function(dateObj) {
            return pad(dateObj.getMinutes());
        },
        s: function(dateObj) {
            return dateObj.getSeconds();
        },
        ss: function(dateObj) {
            return pad(dateObj.getSeconds());
        },
        S: function(dateObj) {
            return Math.round(dateObj.getMilliseconds() / 100);
        },
        SS: function(dateObj) {
            return pad(Math.round(dateObj.getMilliseconds() / 10), 2);
        },
        SSS: function(dateObj) {
            return pad(dateObj.getMilliseconds(), 3);
        },
    },
    // Some common format strings
    masks: {
        default: 'yyyy-MM-dd HH:mm:ss',
        yearDate: 'yyyy-MM-dd',
        mediumTime: 'HH:mm:ss',
    },
    format(dateObj, mask, ) {
        if (typeof dateObj === 'number') {
            dateObj = new Date(dateObj);
        }
        if (Object.prototype.toString.call(dateObj) !== '[object Date]' || isNaN(dateObj.getTime())) {
            throw new Error('Invalid Date in fecha.format');
        }
        mask = this.masks[mask] || mask || this.masks['default'];
        let literals = [];
        // Make literals inactive by replacing them with ??
        mask = mask.replace(this.literal, function($0, $1) {
            literals.push($1);
            return '@@@';
        });
        mask = mask.replace(this.token, ($0) => {
            return $0 in this.formatFlags ? this.formatFlags[$0](dateObj) : $0.slice(1, $0.length - 1);
        });
        // Inline literal values back into the formatted value
        return mask.replace(/@@@/g, function() {
            return literals.shift();
        });
    },

}